import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

// User interface
export class User {
  name: String;
  email: String;
  type: String;
  password: String;
  password_confirmation: String;
}

// User interface
export class Account {
  account_number: Number;
  id_account_type: String;
  account_title: String;
  currency: String;
}

// User interface
export class Category {
  title: String;
}

// search interface
export class Search {
  id_account: String;
  d1: String;
  d2: String;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  // url = "http://127.0.0.1:8000/api/auth/"; //environment.apiUrl;//local
  // urlimg = "http://127.0.0.1:8000/img"; //environment.apiUrlImg;//local

  url = "https://api-gestion.cadorim.com/api/auth/"; //environment.apiUrl;//Prod
  urlimg = "https://api-gestion.cadorim.com/img"; //environment.apiUrlImg;//Prod

  // url = "https://cadorim-dev.cadorim.com/api/auth/"; //environment.apiUrl;//dev-Accounting
  // urlimg = "https://cadorim-dev.cadorim.com/img/"; //environment.apiUrlImg;//dev-Accounting

  // User registration
  register(user: User): Observable<any> {
    return this.http.post(this.url + "register", user);
  }

  //User registration
  updateUser(user: User): Observable<any> {
    return this.http.post(this.url + "update-user", user);
  }

  //Login
  signin(user: User): Observable<any> {
    return this.http.post<any>(this.url + "login", user);
  }

  verify2fa(body): Observable<any> {
    return this.http.post<any>(this.url + "verify-2fa", body);
  }

  // Access user profile
  profileUser(): Observable<any> {
    return this.http.get(this.url + "user-profile");
  }

  // Update user profile
  update(user: User): Observable<any> {
    return this.http.post(this.url + "user-update1", user);
  }

  // All users profile
  getAllUsers(): Observable<any> {
    return this.http.get(this.url + "all-users");
  }

  // All account type
  getAllAccountType(): Observable<any> {
    return this.http.get(this.url + "account-type");
  }

  // create account
  createAccount(account: Account): Observable<any> {
    return this.http.post(this.url + "create-account", account);
  }

  updateAccount(account): Observable<any> {
    return this.http.post(this.url + "update-account", account);
  }

  // All accounts
  getAllAccounts(): Observable<any> {
    return this.http.get(this.url + "all-accounts");
  }
  getAllAccounts2(): Observable<any> {
    return this.http.get(this.url + "all-accounts2");
  }

  getAllAccounts3(): Observable<any> {
    return this.http.get(this.url + "all-accounts3");
  }

  getAllAccounts4(): Observable<any> {
    return this.http.get(this.url + "all-accounts4");
  }

  getAllAccounts5(): Observable<any> {
    return this.http.get(this.url + "all-accounts5");
  }

  getCaissierAccount(): Observable<any> {
    return this.http.get(this.url + "caissier-account");
  }

  // create account
  addAccountType(title: Category): Observable<any> {
    return this.http.post(this.url + "add-category", title);
  }

  // change status
  changeStatus(status, id): Observable<any> {
    return this.http.post(this.url + "user-status", { status: status, id: id });
  }

  // user online
  online(): Observable<any> {
    return this.http.get(this.url + "online");
  }

  getAccount(id): Observable<any> {
    return this.http.post(this.url + "account-detail", { id_account: id });
  }

  // accounts balance
  balance(data?): Observable<any> {
    return this.http.post(this.url + "balance", { year: data });
  }

  // create account
  grandLivre(search: Search): Observable<any> {
    return this.http.post(this.url + "grand-livre", search);
  }

  // create account
  journal(): Observable<any> {
    return this.http.get(this.url + "journal");
  }

  journalDay(): Observable<any> {
    return this.http.get(this.url + "journal-day");
  }

  operateurs(): Observable<any> {
    return this.http.get(this.url + "operateurs");
  }

  clients(): Observable<any> {
    return this.http.get(this.url + "clients");
  }

  myJournalDay(): Observable<any> {
    return this.http.get(this.url + "my-journal-day");
  }

  mySolde(): Observable<any> {
    return this.http.get(this.url + "solde-actuel");
  }

  soldeTemporaire(): Observable<any> {
    return this.http.get(this.url + "solde-temporaire");
  }
  // create account
  journalSearch(search): Observable<any> {
    return this.http.post(this.url + "journal-search", search);
  }

  atlpay(): Observable<any> {
    return this.http.get(this.url + "atlpay");
  }

  atlpay_search(date?): Observable<any> {
    return this.http.post(this.url + "atlpay-search", date);
  }

  gaza(date?): Observable<any> {
    return this.http.post(this.url + "gaza", date);
  }

  gazaconfirmation(id): Observable<any> {
    return this.http.post(this.url + "gazaconfirmation", { id: id });
  }

  enejih(date?): Observable<any> {
    return this.http.post(this.url + "enejih", date);
  }

  enejihconfirmation(id): Observable<any> {
    return this.http.post(this.url + "enejihconfirmation", { id: id });
  }

  saleh(date?): Observable<any> {
    return this.http.post(this.url + "saleh", date);
  }

  salehconfirmation(id): Observable<any> {
    return this.http.post(this.url + "salehconfirmation", { id: id });
  }

  sagne(date?): Observable<any> {
    return this.http.post(this.url + "sagne", date);
  }

  sagneconfirmation(id): Observable<any> {
    return this.http.post(this.url + "sagneconfirmation", { id: id });
  }

  agenceRecherche(data): Observable<any> {
    return this.http.post(this.url + "agence-recherche", data);
  }

  mauritanie(date?): Observable<any> {
    return this.http.post(this.url + "mauritanie", date);
  }

  mauritanieconfirmation(id): Observable<any> {
    return this.http.post(this.url + "mauritanieconfirmation", { id: id });
  }

  imara(date?): Observable<any> {
    return this.http.post(this.url + "imara", date);
  }

  imaraconfirmation(id): Observable<any> {
    return this.http.post(this.url + "imaraconfirmation", { id: id });
  }

  tadamoune(date?): Observable<any> {
    return this.http.post(this.url + "tadamoune", date);
  }

  tadamouneconfirmation(id): Observable<any> {
    return this.http.post(this.url + "tadamouneconfirmation", { id: id });
  }

  bankily(date?): Observable<any> {
    return this.http.post(this.url + "bankily", date);
  }

  bankilyconfirmation(id): Observable<any> {
    return this.http.post(this.url + "bankilyconfirmation", { id: id });
  }

  mauripay(date?): Observable<any> {
    return this.http.post(this.url + "mauripay", date);
  }

  mauripayconfirmation(id): Observable<any> {
    return this.http.post(this.url + "mauripayconfirmation", { id: id });
  }

  //Bnm Wallet CLick Apis
  clickWalletTransfers(date?): Observable<any> {
    return this.http.post(this.url + "click-transfers", date);
  }

  clickWalletConfirmation(id): Observable<any> {
    return this.http.post(this.url + "clickconfirmation", { id: id });
  }

  clickWalletReverseTransfert(id): Observable<any> {
    return this.http.post(this.url + "click-reversal-transfert", { id: id });
  }

  //SEDAD Wallet  Apis
  sedadWalletTransfers(date?): Observable<any> {
    return this.http.post(this.url + "sedad-transfers", date);
  }

  sedadWalletConfirmation(id): Observable<any> {
    return this.http.post(this.url + "sedadconfirmation", { id: id });
  }

  sedadWalletReverseTransfert(id): Observable<any> {
    return this.http.post(this.url + "sedad-reversal-transfert", { id: id });
  }

  masrivy(date?): Observable<any> {
    return this.http.post(this.url + "masrivy", date);
  }

  masrivyconfirmation(id): Observable<any> {
    return this.http.post(this.url + "masrivyconfirmation", { id: id });
  }

  masrivyAccountInfo(): Observable<any> {
    return this.http.post(this.url + "masrivyaccountInfo", {});
  }

  tachout(date?): Observable<any> {
    return this.http.post(this.url + "tachout", date);
  }

  tachoutconfirmation(id): Observable<any> {
    return this.http.post(this.url + "tachoutconfirmation", { id: id });
  }

  selibaby(date?): Observable<any> {
    return this.http.post(this.url + "selibaby", date);
  }

  selibabyconfirmation(id): Observable<any> {
    return this.http.post(this.url + "selibabyconfirmation", { id: id });
  }

  ouldyenja(date?): Observable<any> {
    return this.http.post(this.url + "ouldyenja", date);
  }

  ouldyenjaconfirmation(id): Observable<any> {
    return this.http.post(this.url + "ouldyenjaconfirmation", { id: id });
  }

  ghouloubmouhsine(date?): Observable<any> {
    return this.http.post(this.url + "ghouloubmouhsine", date);
  }

  ghouloubmouhsineconfirmation(id): Observable<any> {
    return this.http.post(this.url + "ghouloubmouhsineconfirmation", {
      id: id,
    });
  }

  // create account  ghouloubmouhsine
  userUpdate(id): Observable<any> {
    return this.http.post(this.url + "user-update", { id: id });
  }

  // create account
  createRate(value): Observable<any> {
    return this.http.post(this.url + "create-rate", value);
  }

  // create account
  getRate(): Observable<any> {
    return this.http.get(this.url + "get-rate");
  }

  // create account
  createFeez(value): Observable<any> {
    return this.http.post(this.url + "create-feez", value);
  }

  // create account
  getFeez(): Observable<any> {
    return this.http.get(this.url + "get-feez");
  }

  commandes(): Observable<any> {
    return this.http.get(this.url + "commandes");
  }
  commande(id): Observable<any> {
    return this.http.post(this.url + "commande", { id: id });
  }

  update_commande(value): Observable<any> {
    return this.http.post(this.url + "update_commande", value);
  }

  orient_commande(value): Observable<any> {
    return this.http.post(this.url + "orient_commande", value);
  }

  search(value): Observable<any> {
    return this.http.post(this.url + "search_commande", value);
  }

  deleteFeez(value): Observable<any> {
    return this.http.post(this.url + "delete-feez", value);
  }

  updateFeez(value): Observable<any> {
    return this.http.post(this.url + "update-feez", value);
  }

  sendEuro(value): Observable<any> {
    return this.http.post(this.url + "send-euro", value);
  }

  fundOrder(value): Observable<any> {
    return this.http.post(this.url + "fund-request", value);
  }

  fundOrderAgence(value): Observable<any> {
    return this.http.post(this.url + "fund-request-agence", value);
  }

  validateOrder(id): Observable<any> {
    return this.http.post(this.url + "validate-order", { id: id });
  }

  fundOrderList(): Observable<any> {
    return this.http.get(this.url + "get-fundOrders");
  }

  // create account
  feedCash(img): Observable<any> {
    return this.http.post(this.url + "feed-cash", img);
  }

  // Wallet Feed Cash Transaction
  feedCashWallet(body): Observable<any> {
    return this.http.post(this.url + "feed-wallet-cash", body);
  }

  multifeedCash(img): Observable<any> {
    return this.http.post(this.url + "multi-cash", img);
  }

  feedCashCaissier(data): Observable<any> {
    return this.http.post(this.url + "feed-caissier", data);
  }

  deleteUser(id): Observable<any> {
    return this.http.post(this.url + "delete-user", { id: id });
  }

  deleteOpp(id): Observable<any> {
    return this.http.post(this.url + "delete-opp", { id: id });
  }

  approuverOpp(id): Observable<any> {
    return this.http.post(this.url + "approuver-opp", { id: id });
  }

  oppDetails(id): Observable<any> {
    return this.http.post(this.url + "opp-details", { id: id });
  }

  oppUpdate(data): Observable<any> {
    return this.http.post(this.url + "opp-update", data);
  }

  resetPassword(data): Observable<any> {
    return this.http.post(this.url + "reset-password", data);
  }

  deleteOrder(id): Observable<any> {
    return this.http.post(this.url + "delete-order", { id: id });
  }

  retrait_extras(id): Observable<any> {
    return this.http.post(this.url + "extras-retrait", { id: id });
  }

  depot_extras(id): Observable<any> {
    return this.http.post(this.url + "extras-depot", { id: id });
  }

  dashboard(): Observable<any> {
    return this.http.get(this.url + "dashboard");
  }

  tempo(): Observable<any> {
    return this.http.get(this.url + "tempo");
  }

  tempo_search(value): Observable<any> {
    return this.http.post(this.url + "tempo-search", value);
  }

  provider(): Observable<any> {
    return this.http.get(this.url + "provider");
  }

  liste_agence(): Observable<any> {
    return this.http.get(this.url + "liste-agence");
  }

  atlpay_details(date): Observable<any> {
    return this.http.post(this.url + "atlpay-details", { date: date });
  }

  histo(): Observable<any> {
    return this.http.get(this.url + "transaction");
  }

  search_history(data): Observable<any> {
    return this.http.post(this.url + "search-history", data);
  }

  retour_caisse(data): Observable<any> {
    return this.http.post(this.url + "retour-caisse", data);
  }

  retour_caisse_agence(data): Observable<any> {
    return this.http.post(this.url + "retour-caisse-agence", data);
  }

  validate_retoureCaisse(id): Observable<any> {
    return this.http.post(this.url + "validate-retoureCaisse", { id: id });
  }

  page(page): Observable<any> {
    return this.http.post(this.url + "page", { page: page });
  }

  rapportQuotidien(date): Observable<any> {
    return this.http.post(this.url + "rapport-quotidien", { date: date });
  }

  tauxdechange(data): Observable<any> {
    return this.http.post(this.url + "tauxdechange", data);
  }

  extras(data): Observable<any> {
    return this.http.post(this.url + "extras", data);
  }

  journalAgence(date, agence): Observable<any> {
    return this.http.post(this.url + "journal-agence", {
      date: date,
      agence: agence,
    });
  }

  journalAgence1(data): Observable<any> {
    return this.http.post(this.url + "journal-agence1", data);
  }

  soldeAgence(data): Observable<any> {
    return this.http.post(this.url + "solde-agence", { account_number: data });
  }

  /***************businesssssss */

  cadorimPayIndex(): Observable<any> {
    return this.http.get(this.url + "business/transactions");
  }

  cadorimPayRetrait(data): Observable<any> {
    return this.http.post(this.url + "business/demande/retrait", data);
  }

  getOrders(): Observable<any> {
    return this.http.get(this.url + "business/get/demande");
  }

  validateRequest(id): Observable<any> {
    return this.http.post(this.url + "business/validate/demande", { id: id });
  }

  searchPayment(data): Observable<any> {
    return this.http.post(this.url + "business/get/payment", data);
  }

  deletePayment(id): Observable<any> {
    return this.http.post(this.url + "business/delete/order", { id: id });
  }

  ccpOrder(data): Observable<any> {
    return this.http.post(this.url + "business/order", data);
  }

  getCcpOrders(): Observable<any> {
    return this.http.get(this.url + "business/order/list");
  }

  validateCcpOrders(id): Observable<any> {
    return this.http.post(this.url + "business/order/validate", { id: id });
  }

  rejetCcpOrders(id): Observable<any> {
    return this.http.post(this.url + "business/order/rejet", { id: id });
  }

  updateCcpOrders(data): Observable<any> {
    return this.http.post(this.url + "business/order/update", data);
  }

  ccpOrders(data): Observable<any> {
    return this.http.post(this.url + "business/order/ccporder", data);
  }

  ccpSolde(): Observable<any> {
    return this.http.get(this.url + "business/order/ccpsolde");
  }

  current_account(): Observable<any> {
    return this.http.get(this.url + "business/current-account");
  }

  ccp_account(): Observable<any> {
    return this.http.get(this.url + "business/ccp-account");
  }

  acceptCcpOrders(id): Observable<any> {
    return this.http.post(this.url + "business/order/accept", { id: id });
  }

  deleteCcpOrders(id): Observable<any> {
    return this.http.post(this.url + "business/order/delete", { id: id });
  }

  business(): Observable<any> {
    return this.http.get(this.url + "business/business");
  }

  business_details(data): Observable<any> {
    return this.http.post(this.url + "business/details", data);
  }

  business_retrait(data): Observable<any> {
    return this.http.post(this.url + "business/retrait", data);
  }

  business_depot_cpp(data): Observable<any> {
    return this.http.post(this.url + "business/depot_ccp", data);
  }

  business_journal(data): Observable<any> {
    return this.http.post(this.url + "business/journal", data);
  }

  business_retrait_cpp(data): Observable<any> {
    return this.http.post(this.url + "business/retrait_ccp", data);
  }

  rapport_quotidien(data?): Observable<any> {
    return this.http.post(this.url + "rapport_quotidien", data);
  }

  journal_quotidien(data): Observable<any> {
    return this.http.post(this.url + "journal_quotidien", data);
  }

  clienthisto(data?): Observable<any> {
    return this.http.post(this.url + "client-histo", data);
  }

  client(): Observable<any> {
    return this.http.get(this.url + "client-list");
  }
}
