import { INavData } from '@coreui/angular';

export const navSaleh
: INavData[] = [
  {
    name: 'Saleh - صالح',
    url: '/cash/saleh',
    icon: 'fa fa-exchange',
  },
  
];

