import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./../../shared/auth.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TokenService } from "../../shared/token.service";
import { AuthStateService } from "../../shared/auth-state.service";
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html",
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errors = null;
  isSignedIn: boolean;
  user: string;
  loading = false;

  constructor(
    public router: Router,
    public fb: FormBuilder,
    public authService: AuthService,
    private token: TokenService,
    private authState: AuthStateService,
    public translate: TranslateService
  ) {
    // translate.addLangs(['fr', 'ar']);
    //if(localStorage.getItem('lang')){ this.translate.use(localStorage.getItem("lang"));}
    //else{this.translate.use('fr'), localStorage.setItem('lang','fr')}

    this.loginForm = this.fb.group({
      email: [],
      password: [],
    });
  }

  ngOnInit() {
    this.user = localStorage.getItem("user");
    if (!this.user) {
      this.logout();
    }
  }

  onSubmit() {
    this.loading = true;
    let user;
    let googlecode;

    this.authService.signin(this.loginForm.value).subscribe(
      (result) => {
        console.log("Resultat", result);
        user = result.user;
        //googlecode=result.qrCode;
        this.responseHandler(result);
        localStorage.setItem("user", JSON.stringify(result.user));
        localStorage.setItem("permission", JSON.stringify(result.permission));
        this.setWithExpiry("ttl", "cadorim", 720);
      },
      (error) => {
        this.loading = false;
        this.errors = error.error;
      },
      () => {
        this.authState.setAuthState(true);
        this.loginForm.reset();
        this.loading = false;
        if (JSON.parse(localStorage.getItem("user")).user_type == "business") {
          this.router.navigateByUrl("/cadorimpay/current_account");
        } else if (
          JSON.parse(localStorage.getItem("user")).user_type == "operateur" &&
          JSON.parse(localStorage.getItem("user")).email != "saleh@cadorim.com"
        ) {
          this.router.navigateByUrl("/cash/transferts");
        } else if (
          JSON.parse(localStorage.getItem("user")).user_type == "operateur" &&
          JSON.parse(localStorage.getItem("user")).email == "saleh@cadorim.com"
        ) {
          this.router.navigateByUrl("/cash/saleh");
        } else if (
          JSON.parse(localStorage.getItem("user")).user_type == "kyc"
        ) {
          this.router.navigateByUrl("/stats/tempo");
        } else {
          this.router.navigate(["dashboard"]);
        }

        //this.router.navigate(["verifyauthentication"],{ state:{ qrcodegoogle: googlecode,user:user } });
      }
    );
  }

  // Handle response
  responseHandler(data) {
    this.token.handleData(data.access_token);
  }

  logout() {
    this.authState.setAuthState(false);
    this.token.removeToken();
    this.router.navigate(["login"]);
  }

  setWithExpiry(key, value, ttl) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + (ttl - 10) * 60000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
}
