import { INavData } from '@coreui/angular';

export const navSuperviseur: INavData[] = [
  {
    name: 'Accueil',
    url: '/dashboard',
    icon: 'icon-home',
  },
      {
        name: 'Solde',
        url: '/operations/solde',
        icon: 'icon-share-alt'
      },

      {
        name: 'Rapport quotidien',
        url: '/operations/rapport-quotidien',
        icon: 'icon-notebook'
      },

      {
        name: 'Soldes client',
        url: '/cash/solde-client',
        icon: 'icon-notebook'
      },

      {
        name: 'Soldes operateurs',
        url: '/cash/operateur-solde',
        icon: 'icon-notebook'
      },

      {
        name: 'Journal',
        url: '/cash/accounts-journal',
        icon: 'icon-notebook'
      },

      {
        name: 'Demande de fonds',
        url: '/operations/fund-orders',
        icon: 'fa fa-money',
      }
];
