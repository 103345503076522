import { INavData } from '@coreui/angular';

export const navItemsGestions
: INavData[] = [
  {
    name: 'Accueil',
    url: '/dashboard',
    icon: 'icon-home',
  },

  {
    name: 'Clients business',
    url: '/cadorimpay/business',
    icon: 'icon-people',
   
  },
  {
    name: 'Demande Depot',
    url: '/cadorimpay/demande_depot',
    icon: 'fa fa-money',
   
  },

  {
    name: 'Demande Retrait',
    url: '/cadorimpay/demande_retrait',
    icon: 'fa fa-money',
   
  },

  {
    name: 'Mon journal',
    url: '/cadorimpay/rapport',
    icon: 'icon-notebook',
   
  },
]