import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { TokenService } from '../../shared/token.service';
import { AuthStateService } from '../../shared/auth-state.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-verify-authenticator',
  templateUrl: './verify-authenticator.component.html',
})
export class VerifyAuthenticatorComponent implements OnInit {

  verifyForm: FormGroup;
  errors = null;
  isSignedIn: boolean;
  user:any;
  loading = false;
  codeHtml: SafeHtml;
  codeAuthenticator='';


  constructor(
    public router: Router,
    public fb: FormBuilder,
    public authService: AuthService,
    private token: TokenService,
    private authState: AuthStateService,
    public translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {


    this.verifyForm = this.fb.group({
      code: ["",[Validators.required,Validators.minLength(4)]],
      user_id:["",[Validators.required]]
    });
    this.getDataFromUrl()

    const htmlString = '<p>Ceci est du code HTML</p>';

    // Assurez-vous de passer le code HTML à bypassSecurityTrustHtml
    // this.codeHtml = this.sanitizer.bypassSecurityTrustHtml(this.codeAuthenticator);
  }

  ngOnInit() {

  }

  onSubmit() {
    this.loading = true;
    this.authService.verify2fa(this.verifyForm.value).subscribe(
      (result) => {
        this.responseHandler(result);
        localStorage.setItem("user", JSON.stringify(result.user));
        localStorage.setItem("permission", JSON.stringify(result.permission));
        this.setWithExpiry("ttl", "cadorim", 720);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log("errUER ",error.error.expired)
        if(error.error.expired){
          this.logout()
          this.router.navigate(["login"]);
        }
        this.errors = error.error;

      },
      () => {
        this.authState.setAuthState(true);
        this.verifyForm.reset();
        this.loading = false;
        if (JSON.parse(localStorage.getItem("user")).user_type == "business") {
          this.router.navigateByUrl("/cadorimpay/current_account");
        } else if (
          JSON.parse(localStorage.getItem("user")).user_type == "operateur" &&
          JSON.parse(localStorage.getItem("user")).email != "saleh@cadorim.com"
        ) {
          this.router.navigateByUrl("/cash/transferts");
        } else if (
          JSON.parse(localStorage.getItem("user")).user_type == "operateur" &&
          JSON.parse(localStorage.getItem("user")).email == "saleh@cadorim.com"
        ) {
          this.router.navigateByUrl("/cash/saleh");
        } else if (
          JSON.parse(localStorage.getItem("user")).user_type == "kyc"
        ) {
          this.router.navigateByUrl("/stats/tempo");
        } else {
          this.router.navigate(["dashboard"]);
        }
      }
    );
  }

  // Handle response
  responseHandler(data) {
    this.token.handleData(data.access_token);
  }

  logout() {
    this.authState.setAuthState(false);
    this.token.removeToken();
    this.router.navigate(["login"]);
  }

  setWithExpiry(key, value, ttl) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + (ttl - 10) * 60000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  getDataFromUrl(){


    if(history.state.user){
      if (history.state.qrcodegoogle) {
        this.codeAuthenticator = history.state.qrcodegoogle
        this.codeHtml = this.sanitizer.bypassSecurityTrustHtml(this.codeAuthenticator);
      }

      this.user = history.state.user;
      this.verifyForm.patchValue({
        user_id:this.user.id
      })
      console.log("VERIF CODE TO SCAN",history.state);
    }else{
      this.logout()
      this.router.navigate(["login"]);
    }


  }
}
