import { INavData } from '@coreui/angular';

export const navItemsCadorimPay
: INavData[] = [
   {
    name: 'Compte courant',
    url: '/cadorimpay/current_account',
    icon: 'fa fa-home',
   
  },

  {
    name: 'Compte CCP',
    url: '/cadorimpay/ccp_account',
    icon: 'fa fa-home',
   
  },
  
];

