import { INavData } from '@coreui/angular';

export const navItemsKyc
: INavData[] = [
   {
    name: 'TEMPO',
    url: '/stats/tempo',
    icon: 'fa fa-calculator',
   
  },

  {
    name: 'Transferts',
    url: '/cash/transferts',
    icon: 'fa fa-paper-plane',
   
  },
  
];

